import React from 'react'
import Flow from 'procedures/Flow'
import _ from 'lodash'
import {getRemovedDependents} from 'procedures/changeDependents/utils'

export const makeFlow = function* (employee, procedureStatus) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
  const welfarePensionInsuranceJoined = _.get(employee, 'welfare_pension_insurance.joined')
  const dependents = getRemovedDependents(employee.employee_dependents, procedureStatus)
  const spouse = dependents.find((dependent) => dependent.relation_type === 'spouse')

  if (healthInsuranceJoined) {
    if (healthInsuranceType === 'its') {
      yield 'R5'
    } else {
      yield 'R4'
    }
  }
  if (welfarePensionInsuranceJoined && spouse) {
    if (healthInsuranceType !== 'kyokai') {
      yield 'R6'
    }
  }
}

const AddDependentFlow = ({location, match}) => (
  <Flow
    {...{makeFlow, location, match}}
    embed="procedure_remove_dependents_data"
    params={{employee_dependent_removed: [1]}}
  />
)

export default AddDependentFlow
