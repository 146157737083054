import React, {useState} from 'react'
import {Section, CheckboxField} from 'jbc-front/components/Form'
import connectForm from 'reportForms/connectForm'
import {toFormValues} from 'utils'
import {formValueSelector, reduxForm, FormSection} from 'redux-form'
import {Notes, SubmitedOn, NeedIssueCertificateCheck} from 'FormFields'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {getRelatedDependents} from 'procedures/changeDependents/utils'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R5Report'
const selector = formValueSelector(formName)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({handleSubmit, submitting, submitText, employee = {}, procedureStatus, needUpgrade, dependents}) => {
  const r5Dependents = getRelatedDependents(dependents, procedureStatus)
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">被扶養者（異動）届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          {['enroll', 'add_dependents'].includes(procedureStatus.procedure_type) && (
            <>
              <LabelMapper name="r5_dependent_param_set" label="扶養情報" />
              {r5Dependents &&
                r5Dependents.map((dependent, index) => {
                  return (
                    <FormSection name={`r5_dependent_param_set[${index}]`} key={dependent.id}>
                      <Section title={`${dependent.last_name} ${dependent.first_name} 資格確認書発行要否`}>
                        <div className="u-mb20">
                          <NeedIssueCertificateCheck />
                        </div>
                      </Section>
                    </FormSection>
                  )
                })}
            </>
          )}
          <Notes />
          <SubmitedOn />
          {['enroll', 'add_dependents'].includes(procedureStatus.procedure_type) && (
            <React.Fragment>
              <p className="u-mt30 u-mb20">
                出力・ダウンロード前に「マイナンバー出力」ボタンを押すか、印刷後に手でマイナンバー（個人番号）を記入してください。
                <br />
                この帳票にマイナンバーを出力・記入しない場合は、以下にチェックを入れてください。その場合、
                <strong>被扶養者異動届提出後1ヶ月以内</strong>に、個人番号届等でマイナンバーの提出が必要になります。
              </p>
              <CheckboxField name="submit_my_number" label="1ヶ月以内にマイナンバーの提出をします。" />
            </React.Fragment>
          )}
          <div className="u-ta-c u-mt30">
            <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
              {submitText}
            </Button>
          </div>
        </Section>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => handleSubmit()}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})

const makeInitialValues = (formData) => {
  const {dependents, procedureStatus, data} = formData
  if (!dependents) {
    return toFormValues(data)
  }
  const r5DependentParamSet = data?.r5_dependent_param_set || []
  const r5Dependents = getRelatedDependents(dependents, procedureStatus)
  const r5DependentValues = r5Dependents.map(
    (dependent) =>
      r5DependentParamSet.find((param_set) => param_set.employee_dependent_id == dependent.id) || {
        employee_dependent_id: dependent.id,
      }
  )
  return {...toFormValues(data), r5_dependent_param_set: r5DependentValues}
}

const connectedForm = connectForm('r5', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee,
    procedureStatus: state.procedureStatuses.current.data,
    needUpgrade: selector(state, 'need_upgrade'),
    dependents: state.procedureStatuses.current.data.employee?.employee_dependents,
  }),
  makeInitialValues,
})

export default connectedForm
